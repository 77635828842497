import React, { useState, useRef, useEffect } from 'react'
import styled from '@emotion/styled'
import { useSpring, animated, useChain, useTrail, useTransition } from 'react-spring'
import get from 'lodash/fp/get'
import ResizeObserver from 'resize-observer-polyfill'
import Form, { addNetlify, addFormState } from './Form'
import { Link } from 'gatsby'

import styles from './header.module.css'

function Header({ onHideNav, onShowNav, showNav, siteTitle, navMenu }) {
  const [open, set] = useState(false)
  const [ref, { width }] = useMeasure()
  const props = useSpring({ width: open ? width : 0, delay: open ? 0 : 500 })
  const [openForm, setOpenForm] = useState(false)

  const bar1Props = useSpring({
    from: { transform: open ? 'rotate(0)' : 'rotate(45deg)' },
    to: { transform: open ? 'rotate(45deg)' : 'rotate(0)' }
  })
  const bar2Props = useSpring({
    from: { opacity: open ? 1 : 0 },
    to: { opacity: open ? 0 : 1 }
  })
  const bar3Props = useSpring({
    from: { transform: open ? 'rotate(0)' : 'rotate(-45deg)' },
    to: { transform: open ? 'rotate(-45deg)' : 'rotate(0)' }
  })

  const trail = useTrail(navMenu && navMenu.length, {
    config: { mass: 5, tension: 2000, friction: 200 },
    delay: open ? 500 : 0,
    opacity: open ? 1 : 0,
    x: open ? 0 : 20,
    height: open ? 80 : 0,
    from: { opacity: 0, x: 20, height: 0 }
  })

  function toggleMenu() {
    setOpenForm(false)
    set(!open)
  }

  // You could use a shared handler definition, however
  // since we are using menu state, we define
  // the handler in this component function.
  const availableHanders = {
    openMenuContact: function () {
      setOpenForm(true)
    }
  }

  function callHandler(event) {
    if (!event) return event
    const handler = availableHanders[event]
    if (typeof handler !== 'function') return null
    return handler
  }


  return (
    <div className={styles.root}>
      <NavContainer onClick={toggleMenu}>
        <ABar style={bar1Props} />
        <ABar style={bar2Props} />
        <ABar style={bar3Props} />
      </NavContainer>
      <Main ref={ref}>
        <Fill style={props}>
          {openForm ? (
            <FormContainer>
              <NetlifyForm name="contact">
                <Form.Group>
                  <Form.Input full name="name" placeholder="Name" />
                  <Form.Input full name="email" placeholder="Email" />
                </Form.Group>
                <Form.TextArea name="message" placeholder="Message" full rows={6}></Form.TextArea>
                <FormButton type="submit">Send Message</FormButton>
              </NetlifyForm>
            </FormContainer>
          ) : (
              <Menu>
                {trail.map(({ x, height, ...rest }, index) => (
                  <animated.li
                    key={index}
                    className="trails-text"
                    style={{ ...rest, transform: x.interpolate(x => `translate3d(0,${x}px,0)`) }}
                  >
                    <animated.div style={{ height }}>
                      {navMenu && navMenu[index] &&
                        <MenuItem navItem={navMenu[index]} setOpenForm={callHandler(navMenu[index].behaviors)} />
                      }
                    </animated.div>
                  </animated.li>
                ))}
              </Menu>
            )}
        </Fill>
      </Main>
    </div>
  )
}

const FormButton = styled.button`
  border: 0;
  border-radius: 10px;
  padding: 1.5rem 2.5rem;
  background-color: none;
  font-size: 2rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
  cursor: pointer;
`

function MenuItem({ navItem, setOpenForm }) {
  const objectType = navItem.__typename
  const title = get(`linkDetails.title`, navItem) || get('title', navItem)
  switch (objectType) {
    case "SanityActionButton":
      return <button style={{ cursor: 'pointer', padding: 0 }} onClick={setOpenForm}>{title}</button>

    case "SanityPageReference":
      return <Link to={'/' + get('page.slug.current', navItem) + '/'}>{title}</Link>

    case "SanityExternalLink":
      return <a href={navItem.externalUrl}>{title}</a>
  }
  return null
}

const FormContainer = styled.div`
  margin-top: 10rem;
  list-style-type: none;
  font-size: 4.6rem;
  text-transform: uppercase;
  padding: 6rem;
`

function useMeasure() {
  const ref = useRef()
  const [bounds, set] = useState({ left: 0, top: 0, width: 0, height: 0 })
  const [ro] = useState(() => new ResizeObserver(([entry]) => set(entry.contentRect)))
  useEffect(() => {
    ro.observe(ref.current)
    return function disconnect() { ro.disconnect }
  }, [])
  return [ref, bounds]
}

const NavButton = styled.button`
  padding: 0;
`

const Fill = styled(animated.div)`
  position: fixed;
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  background: #00eaff;
  overflow: hidden;
`
const Main = styled(animated.div)`
  width: 100%;
  height: 100%;
`
const Menu = styled.ul`
  margin-top: 16rem;
  list-style-type: none;
  font-size: 4.6rem;
  line-height: 1.25;
  color: black;
  font-weight: 800;
  text-transform: uppercase;
  letter-spacing: 4px;
  padding-left: 2rem;
  font-size: 3.5rem;
  line-height: 1;
  @media (min-width: 400px) {
    font-size: 4.6rem;
    line-height: 1.25;
  }
  & li {
    margin-bottom: 2rem;
  }
  & a {
    text-decoration: none;
    color: black;
  }
  & button {
    font-family: 'Nunito Sans', 'Arial Narrow Bold', sans-serif;
    font-weight: 800;
    color: black;
    background: none;
    border: none;
    text-transform: uppercase;
    cursor: pointer;
    font-size: 3.5rem;
    @media (min-width: 400px) {
      font-size: 4.6rem;
    }
  }
`

const NavContainer = styled.div`
  display: inline-block;
  position: absolute;
  top: 1rem;
  left: 1rem;
  cursor: pointer;
  padding: 15px;
  border-radius: 50%;
  background-color: white;
  box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.5);
  z-index: 9999;

  @media (min-width: 768px) {
    top: 3rem;
    left: 3rem;
    padding: 35px;
  }
`

const Bar = styled.div`
  width: 25px;
  height: 3px;
  background-color: #333;
  margin-bottom: 6px;
  transform-origin: 0;

  &:last-child {
    margin-bottom: 0;
  }
`

const ABar = animated(Bar)
const NetlifyForm = addNetlify(Form)

export default Header
