import React from 'react'
import styled from '@emotion/styled'

const HeroSection = styled.div`
  height: 50vh;

  @media (min-width: 768px) {
    height: 100vh;
  }

`

export default HeroSection
