import React from 'react'
import styled from '@emotion/styled'
import footerBG from './footerBG.png'
import fullFooterBG from './fullFooterBG.png'
import { HoverLink } from '../global-styles'
import get from 'lodash/get'
import { Link } from 'gatsby'

function FooterSection({ footerMenu, socialMenu }) {
  return (
    <StyledFooter>
      <DetailsSection>
        <ContactPrivacy>
          {footerMenu && footerMenu.map((link, index) => (
            <LinkItem link={link} key={link._key} />
          ))}
        </ContactPrivacy>
        <p style={{ fontSize: '1.2rem', opacity: 0.6, marginRight: '1rem', textAlign: 'left' }}>
          GOOSEBUMPS and associated logos are trademarks of Scholastic Inc. Goosebumps, the Movie ©2015 Columbia Pictures Industries, Inc. Goosebumps 2, the Movie ©2018 Columbia Pictures Industries, Inc. Goosebumps Night of Scares game software (excluding Columbia Pictures and Scholastic elements) ©2020 Cosmic Forces, Inc. All Rights Reserved.
        </p>
      </DetailsSection>
      <SocialContainer>
        {socialMenu && socialMenu.map((link, index) => (
          <SocialLink key={index}>
            <HoverLink target={get(link, 'linkDetails.browserTarget')} href={link.externalUrl}>
              <img
                src={get(link, 'linkDetails.icon.asset.url')}
                alt={get(link, 'linkDetails.icon.altText')}
              />
            </HoverLink>
          </SocialLink>
        ))}
      </SocialContainer>
    </StyledFooter>
  )
}

function LinkItem({ link }) {
  const objectType = link.__typename
  const title = get(link, `linkDetails.title`) || get(link, 'title')
  switch (objectType) {
    case "SanityActionButton":
      return <button style={{ cursor: 'pointer', padding: 0 }}>{title}</button>

    case "SanityPageReference":
      return <Link to={'/' + get(link, 'page.slug.current') + '/'} target={get(link, 'linkDetails.browserTarget')}>{title}</Link>

    case "SanityExternalLink":
      return <a href={link.externalUrl}>{title}</a>
  }
  return null
}

const StyledFooter = styled.footer`
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
    url(${fullFooterBG}) no-repeat;
  display: flex;
  justify-content: center;
  background-size: cover;
  align-items: center;
  flex-wrap: wrap;
  padding: 3rem;
  @media (min-width: 1183px) {
    justify-content: space-between;
    padding: 3rem 6rem;
    flex-wrap: nowrap;
  }
`

const DetailsSection = styled.div`
  color: white;
  text-align: center;
  a {
    color: white;
  }
  @media (min-width: 1183px) {
    text-align: left;
  }
`

const SocialContainer = styled.ul`
  color: white;
  display: flex;
  list-style: none;
  padding: 0;
  li:not(:last-child) {
    margin-right: 2rem;
  }
`

const ContactPrivacy = styled.p`
  font-size: 1.2rem;
  font-weight: 800;
  letter-spacing: 2px;
  text-transform: uppercase;
  a {
    text-decoration: none;
  }
  a:not(:last-child) {
    margin-right: 5rem;
  }
`

const SocialLink = styled.li``

export default FooterSection
