import React from 'react'
import styled from '@emotion/styled'
import {BoldParagraph} from './global-styles'
import bwCFLogo from './bwCFLogo.png'

function CopySection () {
  return (
    <CopyArea>
      <CFLogo src={bwCFLogo} />
    </CopyArea>
  )
}

const CopyArea = styled.div`
  text-align: center;
  margin: 2rem 1rem;
  padding: 7rem 0;
  @media (min-width: 500px) {
    padding: 12rem 0;
  }
`
const CFLogo = styled.img`
  margin-bottom: 3rem;
`

export default CopySection
